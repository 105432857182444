<template>
    <div class="content-wrapper">
        <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        <div class="content">
            <Markers stage1></Markers>
            <CustomActivatorSelect
                class=""
                :items="filteredConnectorsList"
                :transition="false"
                item-value="type"
                v-model="selectedConnectorType"
                :multiple="false"
            >
                <template v-slot:activator="{ selected, _id }">
                    <TextField
                        label="Источник"
                        hide-details
                        persistent-label
                        class="select-input bot-select"
                        append-icon="mdi-menu-down"
                        :id="_id"
                        :value="selected ? selected.name : ''"
                        readonly
                    >
                    </TextField>
                </template>
            </CustomActivatorSelect>

            <div class="d-flex justify-end">
                <v-btn @click="next" color="main" class="mt-2 white--text" :disabled="!selectedConnector"
                >Далее</v-btn
                >
            </div>
        </div>
    </div>
</template>

<script>
import { intersection } from 'lodash'

import Markers from '@/components/addConnector/Markers.vue'
import Breadcrumbs from '@/components/Breadcrumbs'
import { mapState } from 'vuex'
import CustomActivatorSelect from '../components/inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../components/inputs/BaseInputOutlined.vue'
import TextField from '../components/inputs/TextField.vue'
import { CONNECTOR_TYPES } from '@/vars/general'
import { useBillingStore } from '@/store/stores/billing'
export default {
    name: 'AddConnectorStage1',
    components: { Markers, Breadcrumbs, CustomActivatorSelect, BaseInputOutlined, TextField },

    data: () => ({
        selectedConnectorType: null,
        telephonysTypeListForTariff1:[
            CONNECTOR_TYPES.telephonyMegaphone,
            CONNECTOR_TYPES.telephonyMango,
            CONNECTOR_TYPES.telephonyBeeline,
            CONNECTOR_TYPES.telephonyProstyiZvonki,
            CONNECTOR_TYPES.sipuni,
            CONNECTOR_TYPES.pbxOnline,
            CONNECTOR_TYPES.whatsapp,
            CONNECTOR_TYPES.amoCrm
        ],
        telephonysTypeListForTariff3:[
            CONNECTOR_TYPES.amoCrm
        ],
        billingStore : useBillingStore()
    }),
    computed: {
        ...mapState(['connectorsList']),
        ...mapState('project', ['loggedAddingConnector']),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        breadcrumbs() {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'ДОБАВИТЬ ИСТОЧНИК',
                    disabled: true,
                    to: '',
                },
            ]
        },
        connectorsCount() {
            const connectorsCount = {}
            for (const connector of this.project.connectors) {
                connectorsCount[connector.connectorType] === undefined
                    ? (connectorsCount[connector.connectorType] = 1)
                    : connectorsCount[connector.connectorType]++
            }
            return connectorsCount
        },
        connectorTypesInProject() {
            return this.project.connectors.map(el => el.connectorType)
        },
        filteredConnectorsList() {
            const currentTariff = this.project.billingEnabled && this.billingStore.getCurrentTariff(this.project.id) ? this.billingStore.getCurrentTariff(this.project.id).id : null
            return this.connectorsList
                .filter(con => (con.showCondition ? con.showCondition(this.project) : true))
                .map(con => {
                    const maxCount = this.connectorsList.find(con2 => con2.type === con.type).maxCount
                    //Находим есть ли конкурирующие коннекторы (например yClients нельяз подключить одновременно с universeSoft)
                    const concurrectedConnectorsTypes = con.notIncludes
                        ? intersection(this.connectorTypesInProject, con.notIncludes)
                        : []
                    const concurrency = concurrectedConnectorsTypes.length > 0
                    //Если maxCount === -1, ограничений по количеству коннеторов нет
                    if (maxCount === -1 && !concurrency) {
                        return con
                    }
                    if (concurrency) {
                        return Object.assign({}, con, {
                            name: `${con.name} (уже подключен ${
                                this.connectorsList.find(el => el.type === concurrectedConnectorsTypes[0])
                                    .name
                            })`,
                            disabled: true,
                        })
                    }
                    if (maxCount > 1) {
                        if (this.connectorsCount[con.type] >= maxCount) {
                            return Object.assign({}, con, {
                                name: `${con.name} (подключено ${
                                    this.connectorsCount[con.type]
                                } из ${maxCount} возможных)`,
                                disabled: this.connectorsCount[con.type] >= maxCount ? true : false,
                            })
                        }
                    }

                    if(currentTariff === 'tariff1' && this.telephonysTypeListForTariff1.includes(con.type)){
                        return Object.assign({}, con, {
                            disabled: true,
                            name: `${con.name} (недоступный тариф)`,
                        })
                    }
                    if(currentTariff === 'tariff3' && this.telephonysTypeListForTariff3.includes(con.type)){
                        return Object.assign({}, con, {
                            disabled: true,
                            name: `${con.name} (недоступный тариф)`,
                        })
                    }
                    else{
                        return con
                    }

                    if (this.connectorsCount[con.type] >= maxCount) {
                        return Object.assign({}, con, {
                            disabled: true,
                            name: `${con.name} (уже подключено)`,
                        })
                    } else {
                        return con
                    }

                })
        },
        selectedConnector() {
            if (!this.selectedConnectorType) return
            return this.filteredConnectorsList.find(el => el.type === this.selectedConnectorType)
        },
    },
    methods: {
        next() {
            this.$store.commit('project/updateCurrentAddingConnector', this.selectedConnector)
            const path = this.$router.currentRoute.path
                .split('/')
                .slice(0, 3)
                .join('/')
            this.$router.push(`${path}/addConnectorStage2`)
        },
    },
    async created() {
        if (this.$router.currentRoute.query.fromStage == 3) {
            await this.$store.dispatch('project/deleteConnector', this.loggedAddingConnector.connectorId)
        }
    },
    mounted() {},
}
</script>
<style lang="sass" scoped></style>
